import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import React from 'react'
import styled from 'styled-components'
import '@splidejs/react-splide/css';
import { Link } from 'gatsby';

const Wrap = styled.div`
    margin-top: 5rem;
    margin-bottom: 12rem;

`
const Title = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 57px;
text-align: center;

/* Shukran Rechta DS/Text/Color 2 */

color: #000000;
@media screen and (max-width: 500px) {
    font-size: 32px;
    line-height: 1.5;
}
`
const P = styled.p`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;
text-align: center;

/* Shukran Rechta DS/Text/Color 3 */

color: #424242;
margin-top: -1rem;
@media screen and (max-width: 500px) {
    font-size:16px;
    line-height: 1.5;
}
`
const Grid = styled.div`
display: grid;
grid-template-columns: repeat(2,1fr);
gap: 1rem;
margin: 5px 1rem;
@media screen and (max-width: 600px) {
    grid-template-columns: repeat(1,1fr);
}
`
const GridItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    justify-content:space-between;
/* Shukran Rechta DS / Other elements / Color 1 */

background: #FFFFFF;
/* Shukran Rechta DS / Other elements / Color 5 */

border: 1.5px solid #E0E0E0;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
border-radius: 16px;
@media screen and (max-width: 600px) {
    height: 270px;
}
@media screen and (max-width: 450px) {
    height: 300px;
}
@media screen and (max-width: 400px) {
    height: 350px;
}
@media screen and (max-width: 350px) {
    height: 400px;
}
`
const GridText = styled.p`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    color: #424242;
    margin :0 ;

`
const Review = styled.div`
    display: flex;
    flex-direction: row;
    gap:8px;
    align-items: center;
`
const ReviewsSvg = styled.span`
    
`
const ReviewsStars = styled.h4`
    font-family: 'SF Pro Rounded';
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    color: #000000;

`
const ReviewAuthor = styled.div`
    display: flex;
    flex-direction: column;
   
`
const ReviewAuthorName = styled.h2`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin:0;
    margin-bottom:8px

`
const ReviewAuthorDescription = styled.p`
   font-family: 'SF Pro Rounded';
   font-weight: 700;
   font-size: 16px;
   line-height: 19px;
   color: #00A3FF;
   margin:0

`
const ReadWrap = styled.div`
   display:flex;
    justify-content: center;
    margin-top: 1rem;  
    align-self: center;
    animation: Anime 1.3s; 
    display:none;
@keyframes Anime {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
`
const ReadMore = styled.button`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 32px;
background: #DAFAD4;
border-radius: 8px;
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
color: #2A5E1A;
position: absolute;

`
interface ReviewsProps {
    reviews : any
}

const Reviews: React.FC<ReviewsProps> = ({reviews}) => {
    let str = ''
    let location = ''
    if(typeof window !== 'undefined') {
        location = window.location.pathname
        str = location.replace(/^\/([^\/]*).*$/, '$1')
    }
    const nodePairs = reviews.nodes.reduce(
        (result , value, index, array) => {
           if (index % 2 === 0)
             result.push(array.slice(index, index + 2));
           return result;
         }, []);
      
    React.useEffect(() =>
    {
        const elemnt = document.querySelectorAll('.element2');
        for(let i = 0; i< elemnt.length ; i++) {
            elemnt[i].addEventListener("mouseover", () =>
            {
              elemnt[i].classList.add('active')
            //   elemnt[i].children[0].classList.add('hovertext')
            //   elemnt[i].children[2].classList.add('show')
                
            })
            elemnt[i].addEventListener("mouseleave", () =>
            {
              elemnt[i].classList.remove('active')
            //   elemnt[i].children[0].classList.remove('hovertext')
            //   elemnt[i].children[2].classList.remove('show')
             
            })
        }
        
    })
        return (
            <Wrap>
                <Title>
              {str === '' ? "Utilisé quotidiennement par des milliers d'utilisateurs satisfaits" : "Used daily by thousands of satisfied users"}  
                </Title>
                <P>
                {str === '' ? "Application notée 4,6 étoiles sur 5 sur l'App Store" : "App rated 4.6 out of 5 stars on the App Store"}    
                </P>

               
                <Splide  hasTrack={ false } aria-label="">
<div className="splide__arrows">
    <button className=" splide__arrow--prev" type="button"
    aria-label="Previous slide"
    aria-controls="splide01-track">
   <svg width="104" height="85" viewBox="0 0 104 85" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_350_5900)">
<path d="M4 8.33984C4 3.92157 7.58172 0.339844 12 0.339844H92C96.4183 0.339844 100 3.92157 100 8.33984V68.3398C100 72.7581 96.4183 76.3398 92 76.3398H12C7.58172 76.3398 4 72.7581 4 68.3398V8.33984Z" fill="#FCFCFC" shapeRendering="crispEdges"/>
<g clipPath="url(#clip0_350_5900)">
<path d="M42.6337 47.7061L35.4491 40.5215L73.8183 40.5215C75.0232 40.5215 76.0001 39.5446 76.0001 38.3397C76.0001 37.1347 75.0232 36.1579 73.8183 36.1579L35.4491 36.1579L42.6337 28.9733C43.0597 28.5474 43.2728 27.989 43.2728 27.4306C43.2728 26.8722 43.0597 26.3138 42.6337 25.8879C41.7817 25.0358 40.4002 25.0358 39.5481 25.8879L28.6391 36.797C27.787 37.6489 27.787 39.0304 28.6391 39.8825L39.5481 50.7916C40.4001 51.6437 41.7816 51.6437 42.6337 50.7916C43.4856 49.9395 43.4856 48.558 42.6337 47.7061Z" fill="black"/>
</g>
<path d="M12 1.33984H92V-0.660156H12V1.33984ZM99 8.33984V68.3398H101V8.33984H99ZM92 75.3398H12V77.3398H92V75.3398ZM5 68.3398V8.33984H3V68.3398H5ZM12 75.3398C8.13401 75.3398 5 72.2058 5 68.3398H3C3 73.3104 7.02944 77.3398 12 77.3398V75.3398ZM99 68.3398C99 72.2058 95.866 75.3398 92 75.3398V77.3398C96.9706 77.3398 101 73.3104 101 68.3398H99ZM92 1.33984C95.866 1.33984 99 4.47385 99 8.33984H101C101 3.36928 96.9706 -0.660156 92 -0.660156V1.33984ZM12 -0.660156C7.02944 -0.660156 3 3.36928 3 8.33984H5C5 4.47385 8.13401 1.33984 12 1.33984V-0.660156Z" fill="#4F4F4F"/>
</g>
<defs>
<filter id="filter0_d_350_5900" x="0" y="0.339844" width="104" height="84" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_350_5900"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_350_5900" result="shape"/>
</filter>
<clipPath id="clip0_350_5900">
<rect width="48" height="48" fill="white" transform="translate(28 14.3398)"/>
</clipPath>
</defs>
</svg>


    </button>
    <button className=" splide__arrow--next">
    <svg width="104" height="85" viewBox="0 0 104 85" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_350_5906)">
<path d="M4 8.33984C4 3.92157 7.58172 0.339844 12 0.339844H92C96.4183 0.339844 100 3.92157 100 8.33984V68.3398C100 72.7581 96.4183 76.3398 92 76.3398H12C7.58172 76.3398 4 72.7581 4 68.3398V8.33984Z" fill="#FCFCFC" shapeRendering="crispEdges"/>
<g clipPath="url(#clip0_350_5906)">
<path d="M61.3663 28.9736L68.5509 36.1582L30.1817 36.1582C28.9768 36.1582 27.9999 37.1351 27.9999 38.34C27.9999 39.545 28.9768 40.5218 30.1817 40.5218L68.5509 40.5218L61.3663 47.7064C60.9403 48.1323 60.7272 48.6907 60.7272 49.2491C60.7272 49.8075 60.9403 50.3659 61.3663 50.7918C62.2183 51.6439 63.5998 51.6439 64.4519 50.7918L75.3609 39.8827C76.213 39.0308 76.213 37.6492 75.3609 36.7972L64.4519 25.8881C63.5999 25.036 62.2184 25.036 61.3663 25.8881C60.5144 26.7402 60.5144 28.1217 61.3663 28.9736Z" fill="black"/>
</g>
<path d="M12 1.33984H92V-0.660156H12V1.33984ZM99 8.33984V68.3398H101V8.33984H99ZM92 75.3398H12V77.3398H92V75.3398ZM5 68.3398V8.33984H3V68.3398H5ZM12 75.3398C8.13401 75.3398 5 72.2058 5 68.3398H3C3 73.3104 7.02944 77.3398 12 77.3398V75.3398ZM99 68.3398C99 72.2058 95.866 75.3398 92 75.3398V77.3398C96.9706 77.3398 101 73.3104 101 68.3398H99ZM92 1.33984C95.866 1.33984 99 4.47385 99 8.33984H101C101 3.36928 96.9706 -0.660156 92 -0.660156V1.33984ZM12 -0.660156C7.02944 -0.660156 3 3.36928 3 8.33984H5C5 4.47385 8.13401 1.33984 12 1.33984V-0.660156Z" fill="#4F4F4F"/>
</g>
<defs>
<filter id="filter0_d_350_5906" x="0" y="0.339844" width="104" height="84" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_350_5906"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_350_5906" result="shape"/>
</filter>
<clipPath id="clip0_350_5906">
<rect width="48" height="48" fill="white" transform="translate(28 14.3398)"/>
</clipPath>
</defs>
</svg>


    </button>
  </div>
                <SplideTrack>
                {nodePairs.map((pair:any,index:number)=>
                  <SplideSlide key={index}>
                  <Grid>
                  {pair.map((p:any,index:number) =>
                     <GridItem key={index} className='element2'>
              
                     <GridText>
                  {p.data.text.text}
                     </GridText>
                     <Review>
                         <ReviewsSvg>
                         <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.89099 1.19814L7.89097 1.19814C6.6788 1.65856 5.53134 2.37305 4.54939 3.355C0.845399 7.05899 0.845399 13.086 4.54939 16.79C8.25339 20.494 14.2804 20.494 17.9844 16.79C18.9664 15.8081 19.6809 14.6606 20.1413 13.4484L19.2064 13.0934L20.1413 13.4484C20.6645 12.0709 19.3152 10.7216 17.9377 11.2448L17.9375 11.2449C15.7639 12.071 13.2225 11.6156 11.4731 9.86627C9.72383 8.11696 9.26839 5.57554 10.0945 3.40195L9.15974 3.04667L10.0946 3.40174C10.6178 2.02426 9.26847 0.674952 7.89099 1.19814Z" fill="#9D6806" stroke="white" strokeWidth="2"/>
                        </svg>
                         </ReviewsSvg>
                         <ReviewsStars>
                         {p.data.stars}
                         </ReviewsStars>
                         <ReviewAuthor>  
                             <ReviewAuthorName>
                             {p.data.author}
                             </ReviewAuthorName>
                             <ReviewAuthorDescription>
                             {p.data.description.text}
                             </ReviewAuthorDescription>
                         </ReviewAuthor>
                     </Review>
                     <ReadWrap>
                     <ReadMore>
                         Voir l'avis
                     </ReadMore>
                     </ReadWrap>
       </GridItem>
                  )}  
               
{/* <GridItem to='/' className='element2'>
              
              <GridText>
              “L’application a été adoptée par toute la famille, c’est juste incroyable en magasin, ça évite les mauvaises surprises.”
              </GridText>
              <Review>
                  <ReviewsSvg>
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_357_4493)">
                      <path d="M11.6051 6.13403C11.9733 5.3958 13.0267 5.39579 13.3949 6.13402L14.8929 9.13745C15.0392 9.43085 15.3201 9.63388 15.6446 9.68082L18.9788 10.1631C19.802 10.2822 20.1285 11.2956 19.5297 11.8728L17.1319 14.1841C16.8931 14.4144 16.7839 14.748 16.8406 15.0749L17.4081 18.3494C17.5494 19.165 16.6953 19.7894 15.9611 19.4072L12.9617 17.8462C12.6723 17.6956 12.3277 17.6956 12.0383 17.8462L9.03893 19.4072C8.3047 19.7894 7.45061 19.165 7.59195 18.3494L8.15943 15.0749C8.21607 14.748 8.10695 14.4144 7.86812 14.1841L5.47032 11.8728C4.87151 11.2956 5.19802 10.2822 6.02116 10.1631L9.35543 9.68082C9.67992 9.63388 9.9608 9.43085 10.1071 9.13745L11.6051 6.13403Z" fill="#0E4317"/>
                      <path d="M11.6051 6.13403C11.9733 5.3958 13.0267 5.39579 13.3949 6.13402L14.8929 9.13745C15.0392 9.43085 15.3201 9.63388 15.6446 9.68082L18.9788 10.1631C19.802 10.2822 20.1285 11.2956 19.5297 11.8728L17.1319 14.1841C16.8931 14.4144 16.7839 14.748 16.8406 15.0749L17.4081 18.3494C17.5494 19.165 16.6953 19.7894 15.9611 19.4072L12.9617 17.8462C12.6723 17.6956 12.3277 17.6956 12.0383 17.8462L9.03893 19.4072C8.3047 19.7894 7.45061 19.165 7.59195 18.3494L8.15943 15.0749C8.21607 14.748 8.10695 14.4144 7.86812 14.1841L5.47032 11.8728C4.87151 11.2956 5.19802 10.2822 6.02116 10.1631L9.35543 9.68082C9.67992 9.63388 9.9608 9.43085 10.1071 9.13745L11.6051 6.13403Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </g>
                      <defs>
                      <filter id="filter0_d_357_4493" x="1.66211" y="2.58032" width="21.6758" height="20.9443" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dy="0.5"/>
                      <feGaussianBlur stdDeviation="1.25"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0.376471 0 0 0 0 0.376471 0 0 0 0 0.376471 0 0 0 0.3 0"/>
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_357_4493"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_357_4493" result="shape"/>
                      </filter>
                      </defs>
                  </svg>
                  </ReviewsSvg>
                  <ReviewsStars>
                      5
                  </ReviewsStars>
                  <ReviewAuthor>  
                      <ReviewAuthorName>
                      Andry Ford
                      </ReviewAuthorName>
                      <ReviewAuthorDescription>
                      Andry Ford
                      </ReviewAuthorDescription>
                  </ReviewAuthor>
                
              </Review>
              <ReadWrap>
              <ReadMore>
                  Voir l'avis
              </ReadMore>
              </ReadWrap>
</GridItem> */}
                  </Grid>
             
              </SplideSlide>
                )}
              
                {/* <SplideSlide>
                    <Grid>
                    <GridItem to='/' className='element2'>
                
                <GridText>
                “L’application a été adoptée par toute la famille, c’est juste incroyable en magasin, ça évite les mauvaises surprises.”
                </GridText>
                <Review>
                    <ReviewsSvg>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_357_4493)">
                        <path d="M11.6051 6.13403C11.9733 5.3958 13.0267 5.39579 13.3949 6.13402L14.8929 9.13745C15.0392 9.43085 15.3201 9.63388 15.6446 9.68082L18.9788 10.1631C19.802 10.2822 20.1285 11.2956 19.5297 11.8728L17.1319 14.1841C16.8931 14.4144 16.7839 14.748 16.8406 15.0749L17.4081 18.3494C17.5494 19.165 16.6953 19.7894 15.9611 19.4072L12.9617 17.8462C12.6723 17.6956 12.3277 17.6956 12.0383 17.8462L9.03893 19.4072C8.3047 19.7894 7.45061 19.165 7.59195 18.3494L8.15943 15.0749C8.21607 14.748 8.10695 14.4144 7.86812 14.1841L5.47032 11.8728C4.87151 11.2956 5.19802 10.2822 6.02116 10.1631L9.35543 9.68082C9.67992 9.63388 9.9608 9.43085 10.1071 9.13745L11.6051 6.13403Z" fill="#0E4317"/>
                        <path d="M11.6051 6.13403C11.9733 5.3958 13.0267 5.39579 13.3949 6.13402L14.8929 9.13745C15.0392 9.43085 15.3201 9.63388 15.6446 9.68082L18.9788 10.1631C19.802 10.2822 20.1285 11.2956 19.5297 11.8728L17.1319 14.1841C16.8931 14.4144 16.7839 14.748 16.8406 15.0749L17.4081 18.3494C17.5494 19.165 16.6953 19.7894 15.9611 19.4072L12.9617 17.8462C12.6723 17.6956 12.3277 17.6956 12.0383 17.8462L9.03893 19.4072C8.3047 19.7894 7.45061 19.165 7.59195 18.3494L8.15943 15.0749C8.21607 14.748 8.10695 14.4144 7.86812 14.1841L5.47032 11.8728C4.87151 11.2956 5.19802 10.2822 6.02116 10.1631L9.35543 9.68082C9.67992 9.63388 9.9608 9.43085 10.1071 9.13745L11.6051 6.13403Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                        <filter id="filter0_d_357_4493" x="1.66211" y="2.58032" width="21.6758" height="20.9443" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="0.5"/>
                        <feGaussianBlur stdDeviation="1.25"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.376471 0 0 0 0 0.376471 0 0 0 0 0.376471 0 0 0 0.3 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_357_4493"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_357_4493" result="shape"/>
                        </filter>
                        </defs>
                    </svg>
                    </ReviewsSvg>
                    <ReviewsStars>
                        5
                    </ReviewsStars>
                    <ReviewAuthor>  
                        <ReviewAuthorName>
                        Andry Ford
                        </ReviewAuthorName>
                        <ReviewAuthorDescription>
                        Andry Ford
                        </ReviewAuthorDescription>
                    </ReviewAuthor>
                
                </Review>
                <ReadWrap>
                <ReadMore>
                    Voir l'avis
                </ReadMore>
                </ReadWrap>
</GridItem>
<GridItem to='/' className='element2'>
                
                <GridText>
                “L’application a été adoptée par toute la famille, c’est juste incroyable en magasin, ça évite les mauvaises surprises.”
                </GridText>
                <Review>
                    <ReviewsSvg>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_357_4493)">
                        <path d="M11.6051 6.13403C11.9733 5.3958 13.0267 5.39579 13.3949 6.13402L14.8929 9.13745C15.0392 9.43085 15.3201 9.63388 15.6446 9.68082L18.9788 10.1631C19.802 10.2822 20.1285 11.2956 19.5297 11.8728L17.1319 14.1841C16.8931 14.4144 16.7839 14.748 16.8406 15.0749L17.4081 18.3494C17.5494 19.165 16.6953 19.7894 15.9611 19.4072L12.9617 17.8462C12.6723 17.6956 12.3277 17.6956 12.0383 17.8462L9.03893 19.4072C8.3047 19.7894 7.45061 19.165 7.59195 18.3494L8.15943 15.0749C8.21607 14.748 8.10695 14.4144 7.86812 14.1841L5.47032 11.8728C4.87151 11.2956 5.19802 10.2822 6.02116 10.1631L9.35543 9.68082C9.67992 9.63388 9.9608 9.43085 10.1071 9.13745L11.6051 6.13403Z" fill="#0E4317"/>
                        <path d="M11.6051 6.13403C11.9733 5.3958 13.0267 5.39579 13.3949 6.13402L14.8929 9.13745C15.0392 9.43085 15.3201 9.63388 15.6446 9.68082L18.9788 10.1631C19.802 10.2822 20.1285 11.2956 19.5297 11.8728L17.1319 14.1841C16.8931 14.4144 16.7839 14.748 16.8406 15.0749L17.4081 18.3494C17.5494 19.165 16.6953 19.7894 15.9611 19.4072L12.9617 17.8462C12.6723 17.6956 12.3277 17.6956 12.0383 17.8462L9.03893 19.4072C8.3047 19.7894 7.45061 19.165 7.59195 18.3494L8.15943 15.0749C8.21607 14.748 8.10695 14.4144 7.86812 14.1841L5.47032 11.8728C4.87151 11.2956 5.19802 10.2822 6.02116 10.1631L9.35543 9.68082C9.67992 9.63388 9.9608 9.43085 10.1071 9.13745L11.6051 6.13403Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                        <filter id="filter0_d_357_4493" x="1.66211" y="2.58032" width="21.6758" height="20.9443" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="0.5"/>
                        <feGaussianBlur stdDeviation="1.25"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.376471 0 0 0 0 0.376471 0 0 0 0 0.376471 0 0 0 0.3 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_357_4493"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_357_4493" result="shape"/>
                        </filter>
                        </defs>
                    </svg>
                    </ReviewsSvg>
                    <ReviewsStars>
                        5
                    </ReviewsStars>
                    <ReviewAuthor>  
                        <ReviewAuthorName>
                        Andry Ford
                        </ReviewAuthorName>
                        <ReviewAuthorDescription>
                        Andry Ford
                        </ReviewAuthorDescription>
                    </ReviewAuthor>
                 
                </Review>
                <ReadWrap>
                <ReadMore>
                    Voir l'avis
                </ReadMore>
                </ReadWrap> 
            </GridItem>
                    </Grid>
             
                </SplideSlide> */}
               

             
                </SplideTrack>
  
  </Splide>
                    
               
            </Wrap>
        );
}
export default Reviews