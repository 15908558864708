import React from 'react'
import styled from 'styled-components'
import {StaticImage } from 'gatsby-plugin-image'

const FlexWrap = styled.div`
    display:flex;
    flex-direction:column;
    gap:16px;

`
const FlexRow = styled.div`
    display:flex;
    gap:16px;
    justify-content:space-between;
    @media screen and (max-width: 600px) {
        flex-direction:column;
    }
`
const AnotherFlexRow = styled.div`
    display:flex;
    gap:16px;
    justify-content:space-between;
    @media screen and (max-width: 600px) {
        flex-direction:column-reverse;
        align-items:center;
    }
`
const Cart = styled.div`
    background: #D8FFDE;
    /* Shukran Rechta DS/Halal/Color 1 */
    border: 1.5px solid #0E4317;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap:12px;
    justify-content:space-between;
    align-items: flex-start;
    width: 206.67px;
    height:228px;
    @media screen and (max-width: 600px) {
        width:90%;
        height:fit-content;
    }
`
const CartTitle = styled.h1`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #0E4317;
    margin:0;

`
const CartP = styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    /* Shukran Rechta DS/Halal/Color 1 */

    color: #0E4317;
    margin:0;


`

const Text = styled.h3`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    /* Shukran Rechta DS/Links/Color 2 */

    color: #4CD964;
    margin:0;
`
const GridTitle = styled.h1`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin:0;
    width:80%
`

const CartText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:12px;
    padding: 16px;
    background: #FFFFFF;
    border: 1.5px solid #E0E0E0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    max-width:441.02px;
    justify-content:space-between;
    height: 132px;
    @media screen and (max-width: 600px) {
        width:90%;
        height:fit-content;
    }
`
const GridP = styled.p`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    /* Shukran Rechta DS/Text/Color 2 */

    color: #000000;
    margin:0;

`
const ImageWrap = styled.div`
    height:180px;
    width:260.36px;
    @media screen and (max-width: 600px) {
        width:unset;
        height:unset;
    }
`
const ImageWrapper = styled.div`
    height:263px;
    width:504.84px;
    @media screen and (max-width: 600px) {
        width:unset;
        height:unset;
    }
`
interface FlexProps {
    lang : string
}

const Flex: React.FC<FlexProps> = ({lang}) =>{
   
  return (
    <FlexWrap>
        <FlexRow>
            <Cart>               
                <CartTitle>
                {lang === 'fr-fr'?
                    "Assurez-vous de manger Halal"
                        :
                    "Be sure to eat Halal"
                }  
                </CartTitle>
                <CartP>
                {lang === 'fr-fr'?
                    "Faites vos courses sereinement, Shukran analyze le contenu de votre caddie et vous dit quoi retirer."
                        :
                    "Do your shopping with peace of mind, Shukran analyzes the contents of your shopping cart and tells you what to take out."
                } 
                
                </CartP>
                <Text>
                {lang === 'fr-fr'?
                    "Essayez l'application, c'est gratuit"
                        :
                    "Try the app, it's free"
                }  
                
                </Text>
            </Cart>
            <ImageWrapper>
                <StaticImage src='../images/flex.png' alt="flex"/>             
            </ImageWrapper>
        </FlexRow>
        <AnotherFlexRow>
            <ImageWrap>
                <StaticImage src='../images/flex2.png' alt="flex"/>
            </ImageWrap>
            <CartText>
                <GridTitle>
                {lang === 'fr-fr'?
                    "Découvrez ce que vos produits contiennent"
                        :
                    "Find out what your products contain"
                }  
                
                </GridTitle>
                <GridP>
                {lang === 'fr-fr'?
                    "Étiquettes, ingrédients, composition découvrez ce que vos produits contiennent et éliminez le haram de votre consommation."
                        :
                    "Labels, ingredients, composition Find out what's in your products and eliminate those that are haram from your diet."
                }
                
                </GridP>
            </CartText>
        </AnotherFlexRow>
                
    </FlexWrap>
  )
}

export default Flex