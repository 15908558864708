import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const Wrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 32px;
  align-items:center;
  margin-top:32px;
  .halal {
      width: 206.67px !important;
  height: 206.67px !important;

  }
  @media screen and (max-width: 900px) {
      grid-template-columns: repeat(1,1fr);
  }
`
const Halal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap:12px;
  background: #FFFFFF;
  border: 1.5px solid #E0E0E0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
`
const HalalBtn = styled.span`
font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 33px;
text-align: center;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 8px;
/* Shukran Rechta DS/Halal/Color 2 */

background: #D8FFDE;

border-radius: 8px;
/* Shukran Rechta DS/Haram/Color 1 */

color: #0E4317;
`
const HalalTitle = styled.h1`
    font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* gray/900 */
  width: 100%;
  color: #18181B;
  margin:0;

`
const HalalDescription = styled.p`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin:0;
`
const HalalBtnTwo = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background: #D8FFDE;
  border-radius: 12px;
  margin:0;
`
const HalalBtnTwoTitle = styled.h1`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #0E4317;
  margin:0;

`
const HalalBtnTwoP = styled.p`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 0;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #0E4317;
  margin:0;
`
const HalalCheckerSection = styled.div`
    display: flex;
    flex-direction: column;
    gap:8px;
`
const HalalCheckerText = styled.p`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #4CD964;
  margin:0;
`
const HalalCheckerTitle = styled.h1`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 57px;
  color: #000000;
  margin:0;

`
const HalalCheckerP = styled.p`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin:0;
`
const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  place-content: center;
`

interface HalalCheckerProps {
  lang : string
}

const HalalChecker: React.FC<HalalCheckerProps> = ({lang}) =>{
  return (
    <Wrap>
         <Halal>
            <HalalBtn>
              Halal
            </HalalBtn>
            <HalalTitle>
            {lang === 'fr-fr'?
                    "Blinis mini fins et moelleux 16 pièces 135g"
                        :
                    "Fine and soft mini blinis 16 pieces 135g"
                    }  
              
            </HalalTitle>
            <HalalDescription>
              AUCHAN
            </HalalDescription>
            <ImgWrapper>
            <div>
              <StaticImage src='../images/halachecker.png' alt="hala_pic"/>
            </div>
            </ImgWrapper>
            <HalalBtnTwo>
                <HalalBtnTwoTitle>
                  Halal
                </HalalBtnTwoTitle>
                <HalalBtnTwoP>
                 
                  {lang === 'fr-fr'?
                    "Nous n'avons rien détécté de suspect ou haram."
                        :
                    "We didn't detect anything suspicious or haram."
                    }  
                </HalalBtnTwoP>
            </HalalBtnTwo>
        </Halal>
         <HalalCheckerSection>
            <HalalCheckerText>
              Halal Checker
            </HalalCheckerText>
            <HalalCheckerTitle>
            {lang === 'fr-fr'?
                    "Notre mission : rendre les produits halals fiables plus désirables"
                        :
                    "Our mission: to make reliable halal products more desirable"
                    }  
              
            </HalalCheckerTitle>
            <HalalCheckerP>
            {lang === 'fr-fr'?
                    "En plus de vous aider à éliminer le haram et le suspect de votre panier, notre équipe veut mettre à l'honneur les produits halals, en vous proposant des sélections de produits. Ainsi, acheter halal ne sera plus aussi compliqué"
                        :
                    "In addition to helping you eliminate the haram and the suspect from your basket, our team wants to honor halal products, by offering you product selections. Thus, buying halal will no longer be so complicated"
                    } 
              
            </HalalCheckerP>
        </HalalCheckerSection>
    </Wrap>
  )
}

export default HalalChecker