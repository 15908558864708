import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { slugify } from '../utils/slugify'

const Wrap = styled.div`
margin-top: 1rem;
`
const Text = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
`
const Title = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 57px;
text-align: center;

/* Shukran Rechta DS/Text/Color 2 */

color: #000000;

`
const Paragraph = styled.p`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;
text-align: center;

/* Shukran Rechta DS/Text/Color 3 */

width: 100%;
    align-self: center;
    max-width: 420px;
    color: #424242;
    margin-top: -2rem;

mix-blend-mode: normal;

`
const Grid = styled.div`
display: grid;
grid-template-columns: repeat(3,1fr);
gap:8px;
margin-top: 1rem;
@media screen and (max-width: 800px) {
    grid-template-columns: repeat(1,1fr);
}
`
const ImgGrid = styled.div`
    width: 100%;
    border-radius: 15px;
    .img {
        height: 196px;
    }
    .imgSmall {
        height: 136px !important;
    }
`
const TitleGrid = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 4 ; /* number of lines to show */
-webkit-box-orient: vertical;
/* Shukran Rechta DS/Text/Color 2 */

color: #000000;


`
const AllArticles = styled(Link)`
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;

color: #4F4F4F;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 32px;

background: #FCFCFC;
/* Gray 5 */

border: 1px solid #E0E0E0;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
border-radius: 8px;
transition: .5s all ease;
&:hover {
    border: 1px solid #4F4F4F;
    transition: .5s all ease;
}
`
const GridElement = styled(Link)`
height:371px;
    padding: 12px;
    background: #FFFFFF;
/* Shukran Rechta DS / Other elements / Color 5 */

border: 1.5px solid #E0E0E0;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
border-radius: 16px;
@media screen and (max-width: 800px) {
    height: 300px;
}
@media screen and (max-width: 500px) {
    height: auto;
}
`
const ReadMore = styled.button`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 32px;
background: #DAFAD4;
border-radius: 8px;
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
@media screen and (max-width: 500px) {
    display: none;
}
color: #2A5E1A;
position: absolute;

`
const AllWrap = styled.div`
    display:flex;
    justify-content: center;
    margin-top: 2rem;
`
const ReadWrap = styled.div`
   display:flex;
    justify-content: center;
    margin-top: 1rem;  
    display: none;
    position: relative;
    animation: Anime 1.3s; 
@keyframes Anime {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@media screen and (max-width: 500px) {
    display: none;
}
`
interface ArticlesProps {
    blog : any
}

const Articles: React.FC<ArticlesProps> = ({blog}) => {
    let str = ''
    let location = ''
    if(typeof window !== 'undefined') {
        location = window.location.pathname
        str = location.replace(/^\/([^\/]*).*$/, '$1')
        //  last_segment  = window.location.pathname.split('/');
        // console.log(last_segment)
    }
    const [isHover ,setHover ] = React.useState(false)
    React.useEffect(() =>
    {
        const elemnt = document.querySelectorAll('.element');
        for(let i = 0; i< elemnt.length ; i++) {
            elemnt[i].addEventListener("mouseover", () =>
            {
              elemnt[i].classList.add('active')
              elemnt[i].children[0].classList.add('imgSmall')
              elemnt[i].children[2].classList.add('show')
                
            })
            elemnt[i].addEventListener("mouseleave", () =>
            {
              elemnt[i].classList.remove('active')
              elemnt[i].children[0].classList.remove('imgSmall')
              elemnt[i].children[2].classList.remove('show')
             
            })
        }
        
    })
  
    
        return (
            <Wrap>
                <Text>  
                    <Title>
                    {str=== '' ? 'Tous les articles de notre blog' : 'All articles from our blog'}
                       
                    </Title>
                    <Paragraph>
                    {str=== '' ? "Enquêtes, recettes, news et bons plans : retrouvez nos derniers articles sur notre blog ou directement dans l'application" : "Investigations, recipes, news and tips: find our latest articles on our blog or directly in the application"}
                        
                    </Paragraph>
                </Text>
                <Grid>
                    {blog.nodes.slice(0,3).map((b:any,index:number) =>
                    (
                        <GridElement key={index}  to={`/${b.lang}/article/${b.uid}`} className='element' >
                        <ImgGrid>
                        <GatsbyImage className='img' image={b.data.main_image.gatsbyImageData} alt='Image' />
                    </ImgGrid>
                    <TitleGrid>
                        {b.data.title.text}
                    </TitleGrid>
                    <ReadWrap>
                    <ReadMore>
                       {str=== '' ? 'Lire la suite' : 'Read more'}
                    </ReadMore>
                    </ReadWrap>
                    
                        </GridElement>
                    )
                    )}
   
                </Grid>
                <AllWrap>
                <AllArticles to={str == '' ? '/blog': '/en-us/blog'}>
              {str === '' ? 'Voir tous les articles' : "See all articles"}
                </AllArticles>
                </AllWrap>
              
            </Wrap>
        );
}
export default Articles