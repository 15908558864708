exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-donation-tsx": () => import("./../../../src/pages/donation.tsx" /* webpackChunkName: "component---src-pages-donation-tsx" */),
  "component---src-pages-en-us-donation-tsx": () => import("./../../../src/pages/en-us/donation.tsx" /* webpackChunkName: "component---src-pages-en-us-donation-tsx" */),
  "component---src-pages-en-us-privacy-tsx": () => import("./../../../src/pages/en-us/privacy.tsx" /* webpackChunkName: "component---src-pages-en-us-privacy-tsx" */),
  "component---src-pages-en-us-terms-tsx": () => import("./../../../src/pages/en-us/terms.tsx" /* webpackChunkName: "component---src-pages-en-us-terms-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-recipe-tsx": () => import("./../../../src/templates/recipe.tsx" /* webpackChunkName: "component---src-templates-recipe-tsx" */)
}

