import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { ParallaxProvider } from 'react-scroll-parallax';
import { repositoryConfigs } from './src/utils/prismicPreviews'


export const wrapRootElement = ({ element }) => (
  <>
  <script dangerouslySetInnerHTML={{__html: `
    !function(g,s,q,r,d){r=g[r]=g[r]||function(){(r.q=r.q||[]).push(arguments)};
    d=s.createElement(q);d.src='//d1l6p2sc9645hc.cloudfront.net/gosquared.js';
    q=s.getElementsByTagName(q)[0];q.parentNode.insertBefore(d,q)}(window,document,'script','_gs');

    _gs('GSN-195041-K');
    _gs('set', 'anonymizeIP', true);`}}/>
  <ParallaxProvider>
<PrismicProvider
    internalLinkComponent={({ href, ...props }) => (
      <Link to={href} {...props} />
    )}
  >
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      {element}
    </PrismicPreviewProvider>
  </PrismicProvider>
  </ParallaxProvider>
  </>
  
)