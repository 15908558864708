import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'

const Wrap = styled.div`
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap:32px;
    margin-top: 64px;
    align-items:center;
    .haram {
        width: 206.67px !important;
    height: 206.67px !important;

    }
    @media screen and (max-width: 900px) {
        grid-template-columns: repeat(1,1fr);
    }
`
const HalalChecker = styled.div`
    display: flex;
    flex-direction: column;
    gap:8px;
    @media screen and (max-width: 500px) {
    padding-right: 0;
    }
`
const HalalCheckerText = styled.p`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #4CD964;
    margin:0;

`
const HalalCheckerTitle = styled.h1`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 57px;
    color: #000000;
    @media screen and (max-width: 500px) {
        font-size: 32px;
        line-height: 1.5;
        }
    margin:0;
`
const HalalCheckerP = styled.p`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin:0;
`
const Haram = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:12px;
    padding: 16px;
    background: #FFFFFF;
    border: 1.5px solid #E0E0E0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
`
const HaramBtn = styled.span`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background: #FFD8D8;
    border-radius: 8px;
    color: #5D1414;

`
const HaramTitle = styled.h1`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #18181B;
    margin:0;

`
const HaramaDescription = styled.p`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin:0;

`
const HaramBtnTwo = styled.span`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    /* Shukran Rechta DS/Haram/Color 2 */
    background: #FFD8D8;
    border-radius: 12px;

`
const HaramBtnTwoTitle = styled.h1`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    display: flex;
    align-items: center;
    color: #5D1414;
    margin:0;

`
const HaramBtnTwoP = styled.p`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #5D1414;
    margin:0;
`
interface CheckerProps {
    lang : string
  }
  
  const Checker: React.FC<CheckerProps> = ({lang}) =>{
  return (
    <Wrap>
        <HalalChecker>
            <HalalCheckerText>
                Halal Checker
            </HalalCheckerText>
            <HalalCheckerTitle>
                
                {lang === 'fr-fr'?
                    "Aidez-nous à vous protéger contre les produits harams et suspects"
                        :
                    "Help us to protect you against haram and suspicious products"
                    }   
            </HalalCheckerTitle>
            <HalalCheckerP>
            {lang === 'fr-fr'?
                    "Chez Shukran, nous mettons tout en œuvre pour vous proposer une application aussi fiable que possible. Nous avons ajouté une fonctionnalité pour vous permettre de nous aider à signaler les erreurs sur un produit, et donc à contribuer à l'amélioration continue du service."
                        :
                    "The whole Shukran's team is willing in order to provide you with an application that is as reliable as possible. We have added a feature to allow you to help us report errors on a product, and thus contribute to the continuous improvement of the service."
            }  
                 
            </HalalCheckerP>
        </HalalChecker>
        <Haram>
            <HaramBtn>
                Haram
            </HaramBtn>
            <HaramTitle>
            {lang === 'fr-fr'?
                    "Tanoshi Sauce Soja Japonaise 300ml"
                        :
                    "Tanoshi Japanese Soy Sauce 300ml"
                    }  
                
            </HaramTitle>
            <HaramaDescription>
                Tanoshi
            </HaramaDescription>
            <div>
                <StaticImage src='../images/haramchecker.png' alt='haram_pic'/>
            </div>
            <HaramBtnTwo>
                <HaramBtnTwoTitle>
                {lang === 'fr-fr'?
                    "Alcool"
                        :
                    "Alcohol"
                    }  
                    
                </HaramBtnTwoTitle>
                <HaramBtnTwoP>
                {lang === 'fr-fr'?
                    "C'est un fait bien connu que les musulmans ne boivent pas d'alcool. Il est haram, interdit."
                        :
                    "It is a well-known fact that Muslims do not drink alcohol. It is haram, prohibited."
                    }  
                    
                </HaramBtnTwoP>
            </HaramBtnTwo>
        </Haram>
    </Wrap>
  )
}

export default Checker