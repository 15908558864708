import React from 'react'
import styled from 'styled-components'

const GridWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 1rem;
  margin-top: 1rem;

`
const GridItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:12px;
    padding: 16px;
    background: #FFFFFF;
    border: 1.5px solid #E0E0E0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    @media screen and (max-width: 900px) {
        grid-column-end: 4;
        grid-column-start: 1;
    }
`
const GridTitle = styled.h1`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin:0;


`
const GridP = styled.p`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin:0;

`
const GridOne = styled.div`
    grid-column-start: 1;
    grid-column-end: 4;
    display: flex;
    gap:12px;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    background: #FFFFFF;
    border: 1.5px solid #E0E0E0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
`
const GridOneTitle = styled.h1`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;xt/Color 2 */
  color: #000000;
  margin:0;

`
const grids=[
  {
    grid_title:"Scannez le code barre, on s'occupe du reste!",
    grid_p:'Les ingrédients peuvent contenir beaucoup de choses ! En un simple scan, Shukran fait la lecture pour vous, compare avec sa base de données et vous dit le résultat. Ensuite à vous de choisir.',
    grid_title_en:"Scan the barcode, we take care of the rest!",
    grid_p_en:"Ingredients can contain a lot of things! In a simple scan, Shukran does the reading for you, compares with its database and tells you the result. Then the choice is yours."
  },
  {
    grid_title:"Une communauté engagée",
    grid_p:'Un doute sur la qualité d\'un produit ? Vérifiez les commentaires des autres utilisateurs. Une fois que vous avez essayé le produit, laissez également votre contribution pour aider les autres à faire leur choix.',
    grid_title_en:"A committed community",
    grid_p_en:"A doubt about the quality of a product? Check comments from other users. Once you have tried the product, also leave your contribution to help others make their choice."
  },
  {
    grid_title:'Des sélections de produits à petits-prix',
    grid_p:'Parce que manger correctement, en respectant nos rîtes religieux devrait etre accessible à tous, trouvez les produits idéals parmi notre large choix de sélections produits à petits prix.',
    grid_title_en:"Selection of products at low prices",
    grid_p_en:"Because eating properly, respecting our religious rites should be accessible to all, find the ideal products among our wide choice of product selections at low prices."
  
  }
]
const lastGrid = {
    grid_title:"Le choix halal est l'affaire de tous",
    grid_p:"En contribuant à l'application, vous vous engagez aux côtés de Shukran pour convaincre les marques de proposer des produits plus adaptés à notre communauté" ,
    grid_title_en:"The halal choice is everyone's business",
    grid_p_en:"By contributing to the application, you are committing alongside Shukran to convince brands to offer products that are more suitable for our community."

  }
interface GridProps {
  lang : string
}

const Grid: React.FC<GridProps> = ({lang}) =>{
  return (
    <GridWrap>
        {grids.map((grid:any,index:number) => 

    <GridItem key={index}>
      <GridTitle>
        {lang === 'fr-fr'?grid.grid_title:grid.grid_title_en}
      </GridTitle>
      <GridP>
      {lang === 'fr-fr'?grid.grid_p:grid.grid_p_en}
      </GridP>
    </GridItem>
    )}
       
    <GridOne>
      <GridOneTitle>
      {lang === 'fr-fr'?lastGrid.grid_title:lastGrid.grid_title_en}

      </GridOneTitle>
      <GridP>
      {lang === 'fr-fr'?lastGrid.grid_p:lastGrid.grid_p_en}
      </GridP>
    </GridOne>
        
    </GridWrap>
  )
}

export default Grid