import React from 'react'
import styled, { keyframes } from 'styled-components'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import Flex from './Flex'
import Grid from './Grid'
import Checker from './Checker'
import HalalChecker from './HalalChecker'
import Articles from './Articles'
import Reviews from './Reviews'

const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
`
const Wrap = styled.div`
        max-width:748px;
        margin:0 auto;
        .firstImg {
            margin-top: 5rem;
        }
        @media screen and (max-width: 600px) {
            max-width:85vw; 
        }
`
const Title = styled.h1`
    font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 57px;
text-align: center;

/* Shukran Rechta DS/Text/Color 2 */

color: #000000;
@media screen and (max-width: 500px) {
    font-size: 32px;
    line-height: 1.5;
}
`
const Paragraph = styled.p`
font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;
text-align: center;

/* Shukran Rechta DS/Text/Color 3 */

color: #424242;
margin-top: -1rem;
padding: 0 4rem;
@media screen and (max-width: 500px) {
    padding: 0 1rem;
    font-size: 16px;
line-height: 19px;
}
`
const HalalSection =  styled.div`
    margin-top:1rem;
    @media screen and (max-width: 500px) {
        .gatsby-image-wrapper {
          height:398px;  
        }
    }
`
const loadingAnimation = keyframes`
  0% {
    background: linear-gradient(180deg, #D8FFDE 0%, rgba(216, 255, 222, 0) 100%);
/* Shukran Rechta DS / Other elements / Color 5 */

border: 1.5px solid #E0E0E0;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12));
border-radius: 16px;
  }
  50% {
    background-color: white;
  }
  100% {
    background: linear-gradient(180deg, #D8FFDE 0%, rgba(216, 255, 222, 0) 100%);
/* Shukran Rechta DS / Other elements / Color 5 */

border: 1.5px solid #E0E0E0;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12));
border-radius: 16px;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin-top: 5rem;
  animation: ${loadingAnimation} 2s infinite;
`;
interface MainProps {
    lang : string
    reviews : any
    blog : any
}

const Main: React.FC<MainProps> = ({lang,reviews,blog}) => {
        return (

            <Wrap>
                <ImgWrapper>
                    <StaticImage  className='firstImg' src="../images/main.png" alt='Main' />
                </ImgWrapper>
                <Title className="slow">
                    {lang === 'fr-fr'?
                    "L'application qui vous aide à vérifier si des aliments sont halals."
                        :
                    "The app that assists you in determining whether foods are halal."
                    }   
                </Title>
                <Paragraph>
                {lang === 'fr-fr'?
                    "Shukran décrypte les étiquettes de vos produits pour vérifier la présence d'ingrédients interdits par l'islam."
                    :
                    "Shukran deciphers your product labels to ensure they do not contain any ingredients prohibited by Islam."
                    }   
                </Paragraph>
                <Flex lang={lang}/>
                <Grid lang={lang}/>
                <Checker lang={lang}/>
                <HalalSection >
                    <ImgWrapper>
                        <StaticImage src="../images/main2.png" alt="main2"/>
                    </ImgWrapper>
                </HalalSection>
                <HalalChecker lang={lang}/>
                <Articles blog={blog} />
                <Reviews  reviews={reviews} />
                
             
            </Wrap>
        );
}
export default Main