import React from 'react'
import styled from 'styled-components'
import Navbar from './Navbar'
import '../styles/global.css'
import Footer from './Footer'
import { Box, CircularProgress } from '@mui/material'
const Wrap = styled.div`
    max-width: 1920px;
    margin: 0 auto;
`
interface LayoutProps {
children: React.ReactNode
border: string | null
lang: string
}
const Wrapper = styled.div`
 background: #F3F3F3;
`
const Layout: React.FC<LayoutProps> = ({children,border,lang}) => {

        return (
            <>
            <Wrap>
                <Navbar lang={lang}/>
                <Wrapper>
                    {children}
                </Wrapper>
                <Footer border={border} lang={lang}/>
            </Wrap>
            </>

        );
}
export default Layout