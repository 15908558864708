import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React from 'react'
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Main from '../components/Main';

interface indexProps {
  data: any
  pageContext:any
}

const Home: React.FC<indexProps> = ({data,pageContext}) => {

  const reviews = data.allPrismicReviews
  const blog = data.allPrismicBlogPost

    return (
      <Layout border='' lang={pageContext.lang}>
        <Helmet>
          <html lang={pageContext.lang} dir="ltr" />
          <meta charSet="utf-8" key="charset"/>
          <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no'/>
          <title>{pageContext.title}</title>
          <meta name="twitter:title" content={pageContext.title}/>
          <meta property="og:title" content={pageContext.title}/>
          <meta name="description" content={pageContext.description}/>
          <meta name="twitter:description" content={pageContext.description}/>
          <meta property="og:description" content={pageContext.description}/>
          <meta property="og:type" content="website"/>
          <meta name="twitter:site" content="@shukran"/>
          <meta name="twitter:creator" content="@BCL"/>
          <meta property="og:site_name" content="Shukran"/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://images.prismic.io/shukran/51700c4f-b51e-468f-83e4-c751e6fee2d5_Social-Sharing-image-preview.jpg"/>
          <meta property="og:image" content="https://images.prismic.io/shukran/51700c4f-b51e-468f-83e4-c751e6fee2d5_Social-Sharing-image-preview.jpg"/>
          </Helmet>
          <Main lang={pageContext.lang} reviews={reviews} blog={blog} />
        
      </Layout>
    );
}
export const query = graphql`
  query homeQuery($lang: String) {
    allPrismicReviews(filter: {lang: {eq: $lang}}) {
      nodes {
        data {
          author
          stars
          text {
            text
          }
          description {
            text
          }
        }
      }
    }
    allPrismicBlogPost(filter: {lang: {eq: $lang}}) {
      nodes {
        lang
        uid
        data {
          title{
            text
          }
          main_image{
            gatsbyImageData
          }
        }
      }
    }
    
  }
`
export default withPrismicPreview(Home)